import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FileService } from '../../../../shared/services/file.service';
import { FileModel } from '../../../../shared/models/file.model';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CmsComponent),
      multi: true
    }
  ]
})
export class CmsComponent implements OnInit {

  @Input() cmsToggleFormControl: AbstractControl;
  @Input() contentFormControl: AbstractControl;
  @Input() titleFormControl: AbstractControl;

  cmsEditorConfig = {
    menubar: false,
    branding: false,
    plugins: 'code image link lists',
    toolbar:
      'undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist | link image | code | insertdiv',
    file_picker_types: 'image',
    image_advtab: false,
    image_description: false,
    image_dimensions: false,
    block_unsupported_drop: true,
    images_reuse_filename: true,
    paste_data_images: false,
    height: 'calc(100vh - 88px)',
    images_upload_handler: (blobInfo) => {
      const file = new File([blobInfo.blob()], blobInfo.filename(), {
        type: blobInfo.blob().type
      });

      const promise = new Promise<string>((resolve, reject) => {
        this.fileService.uploadFile({ file: file }, { expand: 'image', public: true }).subscribe((file: FileModel | undefined) => {
          if (file) {
            resolve(file.getImageUrl());
          } else {
            reject('There was a issue with file upload.');
          }
        })
      });
      return promise;
    },
  };

  languages = ['en', 'de', 'fr', 'it'];
  selectedLanguage;
  currentLanguage;
  cmsToggle;
  title;
  content;
  currentTitle;
  currentContent;

  constructor(
    private fileService: FileService,
  ) { }

  ngOnInit(): void {
    this.selectedLanguage = this.languages[0];
    this.currentLanguage = this.selectedLanguage;
    this.cmsToggle = !!this.cmsToggleFormControl.value;
    this.title = this.titleFormControl.value ?? {};
    this.content = this.contentFormControl.value ?? {};
    this.currentTitle = this.title[this.selectedLanguage] || '';
    this.currentContent = this.content[this.selectedLanguage] || '';
  }


  switchLanguage(newLang: string) {
    this.content[this.currentLanguage] = this.currentContent;
    this.currentLanguage = newLang;
    this.selectedLanguage = newLang;
    this.currentTitle = this.title[this.currentLanguage] || '';
    this.currentContent = this.content[this.currentLanguage] || '';
    this.titleFormControl.setValue(JSON.stringify(this.title));
    this.contentFormControl.setValue(JSON.stringify(this.content));
  }

  updateToggleFormControl() {
    this.cmsToggleFormControl.setValue(this.cmsToggle);
    this.titleFormControl.setValue(JSON.stringify(this.title));
    this.contentFormControl.setValue(JSON.stringify(this.content));
  }

  updateTitleFormControl() {
    this.title[this.currentLanguage] = this.currentTitle;
    this.titleFormControl.setValue(JSON.stringify(this.title));
  }

  updateContentFormControl() {
    this.content[this.currentLanguage] = this.currentContent;
    this.contentFormControl.setValue(JSON.stringify(this.content));
  }

}
