import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../../shared/services/company.service';
import { CompanyDomainModel, CompanyModel } from '../../../shared/models/company.model';
import { ContactModel } from '../../../shared/models/contact.model';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { FileModel } from '../../../shared/models/file.model';
import { FileService } from '../../../shared/services/file.service';

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
})
export class CompanyFormComponent implements OnInit {

  @Input() company: CompanyModel;
  @Input() showIsOnDisplayToggle: boolean = true;

  private companyImage: File;
  private contactImages: any[] = [];
  private subscriptions: Subscription[] = [];

  form: FormGroup;
  contacts: FormArray;
  allowedDomains: FormArray;
  isSaving: boolean = false;
  showOnDisplay: boolean = false;
  showAddressFields: boolean = false;

  canceled = new EventEmitter<boolean>();
  companySaved = new EventEmitter<CompanyModel>();

  

  constructor(
    private companyService: CompanyService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.showOnDisplay = Boolean(this.company.isOnDisplay);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  setCompanyImage(file: any): void {
    this.companyImage = file;
  }

  setContactImage(file: any, index: number): void {
    this.contactImages[index] = file;
  }

  closeModal() {
    this.canceled.emit(true);
  }

  save(): void {
    if (this.form.valid) {
      this.isSaving = true;
      this.company.setAttributes(this.form.value);
      this.setImages();
      this.companyService.saveCompany(this.company).subscribe((company: CompanyModel | unknown) => {
        this.isSaving = false;
        if (company) {
          this.company = company as CompanyModel;
          this.companySaved.emit(this.company);
          this.toast.success('Company succesfully saved!');
        }
      });
    } else {
      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();
    }
  }

  removeCompany() {
    this.isSaving = true;
    this.companyService.removeCompany(this.company.id).subscribe((company: CompanyModel | unknown) => {
      this.isSaving = false;
      if (company) {
        this.company = company as CompanyModel;
        this.companySaved.emit(this.company);
        this.toast.success('Company succesfully removed!');
      }
    });
  }

  addContact(): void {
    this.contacts = this.form.get('contacts') as FormArray;
    this.contacts.push(this.createContactFormGroup(new ContactModel({})));
  }

  addAllowedDomain(): void {
    this.allowedDomains = this.form.get('allowedDomains') as FormArray;
    this.allowedDomains.push(this.createAllowedDomainFormGroup(new CompanyDomainModel({})));
  }

  removeContact(index: number): void {
    this.contacts = this.form.get('contacts') as FormArray;
    this.contacts.removeAt(index);
  }

  removeAllowedDomain(index: number): void {
    this.allowedDomains = this.form.get('allowedDomains') as FormArray;
    this.allowedDomains.removeAt(index);
  }

  getContactById(id: number) {
    return this.company?.contacts?.find((contact: ContactModel) => contact.id == id) || new ContactModel();
  }

  get hasContacts() {
    return this.company?.contacts?.length > 0 || this.contacts?.length > 0;
  }

  get availableReceptionDisplays() {
    return CompanyModel.availableReceptionDisplays;
  }

  get availableReceptionInstances() {
    return CompanyModel.availableReceptionInstances;
  }

  private initForm() {
    this.form = new FormGroup({
      name: new FormControl(this.company?.name, Validators.required),
      elevatorGroup: new FormControl(this.company?.elevatorGroup),
      receptionNumber: new FormControl(this.company?.receptionNumber),
      receptionText: new FormControl(this.company?.receptionText),
      receptionDisplay: new FormControl(this.company?.receptionDisplay),
      receptionInstance: new FormControl(this.company?.receptionInstance),
      street: new FormControl(this.company?.address?.street),
      city: new FormControl(this.company?.address?.city),
      zipcode: new FormControl(this.company?.address?.zipcode),
      state: new FormControl(this.company?.address?.state),
      country: new FormControl(this.company?.address?.country),
      isOnDisplay: new FormControl(this.company?.isOnDisplay),
      hasSuccessModal: new FormControl(this.company?.hasSuccessModal),
      successModalTitle: new FormControl(this.company?.successModalTitle),
      successModalBody: new FormControl(this.company?.successModalBody),
      contacts: new FormArray(this.getCompanyContactsAsFormArray()),
      allowedDomains: new FormArray(this.getCompanyAllowedDomainsAsFormArray())
    });
    this.contacts = this.form.get('contacts') as FormArray;
    this.allowedDomains = this.form.get('allowedDomains') as FormArray;
  }

  private getCompanyContactsAsFormArray() {
    let contacts: FormGroup[] = [];
    this.company?.contacts?.forEach((contact) => {
      contacts.push(this.createContactFormGroup(contact))
    });

    return contacts;
  }

  private getCompanyAllowedDomainsAsFormArray() {
    let allowedDomains: FormGroup[] = [];
    this.company?.allowedDomains?.forEach((el) => {
      allowedDomains.push(this.createAllowedDomainFormGroup(el))
    });

    return allowedDomains;
  }

  private createContactFormGroup(contact: ContactModel): FormGroup {
    return new FormGroup({
      id: new FormControl(contact?.id),
      name: new FormControl(contact?.name, Validators.required),
      phoneNumber: new FormControl(contact?.phoneNumber),
      description: new FormControl(contact?.description),
    });
  }

  private createAllowedDomainFormGroup(companyDomain: CompanyDomainModel): FormGroup {
    return new FormGroup({
      id: new FormControl(companyDomain?.id),
      domain: new FormControl(companyDomain?.domain, Validators.required),
      receptionEmail: new FormControl(companyDomain?.receptionEmail),
    });
  }

  private setImages() {
    if (this.companyImage) {
      this.company.imageFile = this.companyImage;
    }

    this.contactImages?.forEach((image, i) => {
      const hasContact = this.company.contacts?.[i];
      if (hasContact) {
        this.company.contacts[i].imageFile = image;
      }
    });
  }

}
