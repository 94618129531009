import { BaseModel } from './base.model';
import { ImageModel } from './image.model';

export class FileModel extends BaseModel {
  id: number;
  imageId: number;
  mimeType: string;
  storageType: string;
  storageKey: string;
  size: number;

  file?: File;
  image: ImageModel;

  get childModels(): {} {
    return {
      image: ImageModel,
    };
  }

  toApi(model?: this): {} {
    return {
      ...super.toApi(model),
      ...((model && model.file) ? { file: model.file } : {}),
    };
  }

  getImageUrl() {
    return this.image?.originalUrl ?? './assets/images/placeholder-image.png';
  }


}
