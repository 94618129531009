import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { UserModel } from '../models/user.model';
import { GridDataInterface, PaginationModel } from '../models/pagination.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { catchError, map } from 'rxjs/operators';
import { TranslationService } from './translation.service';
import { FileModel } from '../models/file.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  // private USER = new BehaviorSubject<UserModel>(undefined);
  // user = this.USER.asObservable();

  // get userInfo(): UserModel {
  //   return this.USER.value;
  // }

  constructor(
    private authService: AuthService,
    private api: BaseApiService,
    private translationService: TranslationService,
  ) {
    // AuthService.LOGIN.subscribe(() => {
    //   this.initializeLanguage();
    // });

    // TranslationService.TRANSLATED.subscribe(lang => {
    //   this.changeUserLanguage(lang);
    // });
  }

  // initializeLanguage() {
  //   return this.authService.getUserByToken().subscribe((user: UserModel) => {
  //     if (user?.profile?.language) {
  //       this.translationService.translateTo(user.profile.language);
  //       this.translationService.setLanguageToStorage(user.profile.language);
  //     }
  //     this.USER.next(new UserModel(user));
  //   });
  // }

  // private changeUserLanguage(lang: string): void {
  //   this.api.put(`/users/me`, { language: lang }).subscribe();
  // }

  uploadFile(file: any, queryParams:any = {}): Observable<FileModel | undefined> {

    return this.api.postFormData(`/files`, file, queryParams).pipe(
      map(response => {
        return new FileModel(response.body);
      }),
      catchError((err) => {
        return of(undefined);
      }),
    );
  }


}
